.servicesContentWrapper {
  position: absolute;
  width: 100%;
  background: url("../../extracontent/services/serviceassets/serviceBackground.jpg")
    rgba(0, 0, 0, 0.253);
  height: 75%;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}

.servicesContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.serviceHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0em 1.5em;
}

.serviceHeader h1 {
  color: white;
}

.serviceHeader h3 {
  color: white;
}

@media (min-width: 767px) {
  .serviceHeader h1 {
    font-size: 3.5rem;
  }

  .serviceHeader h3 {
    font-size: 1.8rem;
  }
}
