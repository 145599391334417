@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  color-scheme: dark;
}

/* Mobile View */

.mobileNavWrapper {
  width: 100%;
}

.webName {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.webName h3 {
  color: white;
  font-size: 2rem;
}

.navHam {
  margin-right: 0.3em;
}

.mobileNavContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #232526;
  position: fixed;
  width: 100%;
  z-index: 6;
}

.mobileNavContent img {
  width: 35px;
}

.navComponents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 3em;
  background: #232526;
  position: fixed;
  width: 100%;
  top: -100%;
  transition: all 0.5s ease-in-out;
  z-index: 5;
}

.navComponents a {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
}

.navComponents a:hover {
  color: white;
  background: #555;
  text-decoration: none;
}

.navComponentsMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 3em;
  background: #232526;
  position: fixed;
  width: 100%;
  top: 45px;
  /* height: 100%; */
  transition: all 0.8s ease-in-out;
  z-index: 5;
}

.navComponentsMobile a {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 3em; */
  padding: 0.3em;
}

.navComponentsMobile a:hover {
  color: white;
  background: #555;
  text-decoration: none;
}

.signButtonWrapper a:hover {
  background: none;
}

.signButtonWrapper {
  padding-bottom: 1.1em;
}

#signUpButton {
  width: 40vw;
  font-size: 1.2rem;
  font-weight: bold;
  border: 3px solid white;
  border-radius: 10px;
}

#signUpButton:focus {
  box-shadow: none !important;
}

/* Desktop View */

.desktopNavWrapper {
  width: 100%;
}

.desktopNavContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 3;
  background: transparent;
}

.desktopNavContent.active {
  background: #232526;
  transition: all 2s ease-in-out;
  position: fixed;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.webNameDesktop {
  display: flex;
  align-items: center;
  margin-left: 1em;
}

.webNameDesktop h3 {
  color: white;
  font-size: 2rem;
}

.desktopNavContent img {
  width: 35px;
}

.desktopNavComponents {
  display: flex;
  align-items: center;
}

.desktopNavComponents a {
  color: white;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 1.2em;
}

.desktopNavComponents a:hover {
  color: lightgray;
  border: none;
  text-decoration: none;
}

.desktopNavComponents.active a:hover {
  color: white;
  text-decoration: none;
  border-bottom: 5px solid white;
  padding-bottom: 15px;
}

.desktopNavComponents.active .desktopSignUpWrapper a:hover {
  border: none;
}

.desktopSignUpWrapper {
  padding: 0px 15px;
}

.desktopSignUpWrapper a {
  padding: 0;
}

.desktopSignUpWrapper a:hover {
  border: none;
}

#desktopSignUpButton {
  font-weight: bold;
  border: 3px solid white;
  font-size: 1em;
  border-radius: 10px;
}

#desktopSignUpButton:focus {
  box-shadow: none;
}

.homeContentWrapper {
  width: 100%;
}

.homeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: url(/static/media/homeBackground.a241d6dd.jpg) rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  padding: 0em 1.5em;
}

.homeContent h1 {
  color: white;
  font-family: "Poppins", sans-serif;
}

.homeContent h3 {
  color: white;
  margin-bottom: 0.7em;
}

.homeButtonWrapper {
  display: flex;
}

.homeButtonWrapper > * {
  margin: 0.5em;
}

.homeButton1 {
  padding: 0.7em;
  background: none;
  color: white;
  border: 3px solid #fff;
  font-weight: bold;
  transition: all 0.4s ease-in-out;
}

.homeButton1:hover {
  background: #fff;
  color: #000;
}

.homeButton2 {
  padding: 0.9em;
  background: #fff;
  color: #000;
  font-weight: bold;
  border: none;
  box-shadow: inset 0 0 0 0 #000;
  transition: all 0.4s ease-in-out;
}

.homeButton2:hover {
  box-shadow: inset 10em 0 0 0 #000;
  color: white;
}

@media (min-width: 49em) {
  .homeContent h1 {
    font-size: 3.5rem;
  }

  .homeContent h3 {
    font-size: 2rem;
  }
}

.homePartTwoContentWrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  background: white;
  height: 30vh;
}

.homePartTwoContent h2 {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 0.5em;
}

@media (min-width: 820px) {
  .homePartTwoContent h2 {
    font-size: 2.8rem;
    margin-top: 0.5em;
  }
}

#cardHomeUI {
  width: 16em;
  border-radius: 12px;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

#cardHomeOverflow {
  overflow: hidden;
  border-radius: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: none !important;
}

#cardUIImg {
  transition: all 0.4s ease-in-out;
}

#cardUIImg:hover {
  transform: scale(1.8);
}

.cardUIContent {
  padding: 0.5em;
}

@media (min-width: 54em) {
  #cardHomeUI {
    width: 25rem;
  }
}

#cardHomeUI2 {
  width: 16em;
  border-radius: 8px;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

#cardHomeOverflow2 {
  overflow: hidden;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: none !important;
}

#cardUIImg2 {
  transition: all 0.4s ease-in-out;
}

#cardUIImg2:hover {
  transform: scale(1.8);
}

.cardUIContent2 {
  padding: 0.5em;
}

@media (min-width: 54em) {
  #cardHomeUI2 {
    width: 16.2rem;
  }
}

.cardHomeContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardHomeRow1 {
  display: grid;
  grid-gap: 1.5em;
  gap: 1.5em;
  margin-bottom: 1.5em;
  margin-top: 2em;
}

.cardHomeRow2 {
  display: grid;
  grid-gap: 1.5em;
  gap: 1.5em;
  margin-bottom: 6em;
}

@media (min-width: 54em) {
  .cardFluidContainer {
    width: 100%;
  }

  .cardHomeContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardHomeRow1 {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 1.5em;
    margin-top: 2em;
  }

  .cardHomeRow2 {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 3.5em;
  }
}

.homeFooterContent {
  width: 100%;
  margin-top: -2em;
}

.homeFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
}

.footerTitles {
  text-align: center;
  color: white;
  padding: 0em 1.5em;
}

.footerTitles h3:nth-child(1) {
  margin-top: 1em;
  margin-bottom: 1em;
}

.footerTitles h3:nth-child(2) {
  margin-bottom: 1em;
}

.footerTitles h3 {
  font-size: 1.4rem;
}

#homeSubscription {
  display: flex;
  align-items: center;
}

#homeTextbox {
  width: 30vw;
}

#homeTextbox:focus {
  box-shadow: none !important;
}

#homeSubscribeButton {
  margin-left: 0.5em;
  z-index: 1;
}

#homeSubscribeButton {
  font-weight: bold;
  border: 3px solid white;
}

#homeSubscribeButton:focus {
  box-shadow: none !important;
}

.footerLinksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.footerLinks h5 {
  color: white;
  font-size: 1.5rem;
}

.footerLinks a {
  line-height: 2em;
  color: white;
}

.footerLinks a:hover {
  color: white;
  /* text-decoration: none; */
}

.copyright h5 {
  color: white;
}

.socialLinks {
  display: flex;
}

.socialLinks a {
  color: white;
  margin: 0.5em;
}

.socialLinks a:hover {
  color: #555;
}

.scrollOnTopWrapper {
  width: 100%;
}

.scrollOnTop {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1em;
}

#scrollUpButton {
  font-size: 2rem;
  margin-right: 0.5em;
}

#scrollUpButton:focus {
  box-shadow: none !important;
}

@media (min-width: 53em) {
  .homeFooterContent {
    margin-top: 1em;
  }

  .footerLinksWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  #scrollUpButton {
    font-size: 2.5rem;
    margin-right: 0.5em;
  }

  .socialLinks a {
    font-size: 1.2rem;
  }

  .footerTitles h3 {
    font-size: 1.7em;
  }
}

.servicesContentWrapper {
  position: absolute;
  width: 100%;
  background: url(/static/media/serviceBackground.03e01b89.jpg)
    rgba(0, 0, 0, 0.253);
  height: 75%;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}

.servicesContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.serviceHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0em 1.5em;
}

.serviceHeader h1 {
  color: white;
}

.serviceHeader h3 {
  color: white;
}

@media (min-width: 767px) {
  .serviceHeader h1 {
    font-size: 3.5rem;
  }

  .serviceHeader h3 {
    font-size: 1.8rem;
  }
}

.servicesPartTwoWrapper {
  position: absolute;
  width: 100%;
  top: 100%;
}

.servicesPartTwoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.servicesPartTwoContent h1 {
  margin-top: 0.5em;
}
#serviceCard {
  width: 100%;
  border-radius: 7px;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253);
}

.serviceImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceImg img {
  width: 90px;
  margin-top: 2em;
}

#serviceCardInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#serviceCardInfo p {
  text-align: center;
}

.serviceButtonWrapper {
  width: 100%;
}

.serviceButton {
  background: #000;
  border: none;
  font-weight: bold;
  width: 100%;
  padding: 0.3em 0.5em;
  margin-top: 0.35em;
  margin-bottom: -0.3em;
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.serviceButtonWrapper::after {
  content: "";
  width: 0%;
  height: 2.2em;
  background: #ff3300;
  display: block;
  position: absolute;
  bottom: 1.3em;
  transition: all 0.5s ease-in-out;
}

.serviceButtonWrapper:hover::after {
  width: 82%;
}

.serviceButton h5 {
  position: relative;
  z-index: 2;
}

.serviceFluidContainer {
  width: 100%;
}

.serviceCardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4.5em;
  margin-top: 2em;
}

.serviceCards {
  display: grid;
  grid-row-gap: 3em;
}

@media (min-width: 767px) {
  .serviceCardContent {
    margin-top: 3.5em;
  }

  .serviceCards {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 8em;
    transform: scale(1.2);
  }

  .serviceCardContent {
    margin-top: 6em;
    padding-bottom: 10em;
  }
}

.productsContentWrapper {
  position: absolute;
  width: 100%;
  background: url(/static/media/products.6837c991.jpg) rgba(0,0,0,0.253);
  height: 60%;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}

.productsContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.productsContent h1 {
    color: white;
}


.productWavesWrapper {
  width: 100%;
}

.productWavesContainer {
  position: absolute;
  width: 100%;
  bottom: -1.8em;
  transform: rotateY(180deg);
}


.carouselWrapper {
  width: 100%;
}

.carouselContent {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -25em;
  width: 100%;
}

.carouselInnerWrapper {
  width: 100%;
  background: white;
  padding: 2.5em;
}

.slick-prev::before,
.slick-next::before {
  color: #555 !important;
}

.carouselSlides img {
  margin: 0 auto;
  width: 350px;
}

.carouselInnerWrapper h1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  margin-bottom: 1.5em;
}

.icons {
  color: red;
}

/* Sliders */

.carouselArrow {
  font-size: 1.5rem;
}

.productsCardWrapper {
  width: 100%;
}

.productsCardContent {
  position: absolute;
  width: 100%;
  bottom: -97em;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}

.productsCardRow {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
}

@media (min-width: 767px) {
  .productsCardContent {
    transform: scale(1);
    position: absolute;
    width: 100%;
    bottom: -70em;
  }

  .productsCardRow {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 3em;
    gap: 3em;
  }
}

.overlay {
  width: 100%;
}

.overlayContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.overlayContent:hover {
  opacity: 1;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.overlayContent h2 {
  color: white;
}

#productButtons {
  margin: 0.3em;
  font-weight: bold;
}

#productButtons:focus {
  box-shadow: none !important;
}

.productsCard:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253);
}

.signUpBG {
  background: url(/static/media/signUpBG.a8a31f75.jpg)
    rgba(0, 0, 0, 0.253);
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: multiply;
  overflow-x: hidden;
}

/* General */

.signUpContentWrapper {
  width: 100%;
}

.signUpContentPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.signUpContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 7.5em;
}

.signUpSignUp {
  transform: scale(0.85);
}

/* Mobile */

.signUpPhotoCard {
  display: none;
}

#penImg {
  width: 6em;
  margin-top: -5em;
}

#cardContent {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 2em;
  border-radius: 25px;
  color: white;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* Name Input */

#nameInput {
  margin-top: 1em;
}

/* Credentials Input */

#credentialsInput {
  margin-top: 1em;
  width: 225px;
}

/* TOS */

.signUpTOS {
  display: flex;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
}

/* Button */

.signUpButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

#signUpCardButton {
  font-weight: bold;
  font-size: 1.1rem;
  border: 3px solid white;
  border-radius: 5px;
}

#signUpCardButton:focus {
  box-shadow: none !important;
}

/* Desktop */

@media (min-width: 767px) {
  .signUpPhotoCard {
    display: flex;
    flex-direction: column;
  }

  .signUpSignUp {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .signUpPhotoCardImg {
    background: url(/static/media/signupDesign.11eed17b.jpg)
      rgba(0, 0, 0, 0.5);
    width: 380px;
    height: 608px;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .signUpPhotoCardImg h1 {
    color: white;
    text-align: center;
    margin-top: 6em;
  }

  #credentialsInput {
    width: 390px;
  }

  #cardContent {
    border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

/* Card Compile */

.signUpCardCompile {
  width: 100%;
  margin-top: 8vh;
  background: white;
}

.signUpCardCompileWrapper {
  width: 100%;
}

.signUpCardCompileContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  margin-top: -4em;
}

.signUpCardCompileContent h1 {
  margin-bottom: 1em;
  text-align: center;
}

.signUpCardCompileRow {
  display: grid;
  grid-gap: 2.5em;
  gap: 2.5em;
}

@media (min-width: 881px) {
  .signUpCardCompileWrapper {
    margin-bottom: 3em;
  }

  .signUpCardCompileRow {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  .signUpCardCompileContent {
    margin-top: -1em;
  }
}

/* Card Button */

#subscribeButton {
  font-size: 1rem;
  font-weight: bold;
  border: 3px solid white;
}

#subscribeButton:focus {
  box-shadow: none !important;
}

/* Individual Cards */

#cardCompileUI {
  transition: all 0.4s ease-in-out;
}

#cardCompileUI:hover {
  transform: scale(1.1);
}

.hikingContentWrapper {
  width: 100%;
}

.hikingContent {
  height: 100vh;
}

.hikingImgWrapper img {
  width: 100%;
  object-fit: cover;
}

.hikingInfo {
  width: 100%;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.hikingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Title */

.cardTitle {
  margin-top: 0.8em;
  text-align: center;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle h1 {
  font-size: 2rem;
  font-family: "Dancing Script", cursive;
}

.cardTitle::after {
  content: "";
  background: #fff;
  display: block;
  margin-top: 0.3em;
  width: 20%;
  height: 0.31em;
}

.hikingBorderContent {
  text-align: center;
  border: 3px dashed #fff;
  margin: 1.5em 1.5em 1em 1.5em;
  padding: 1em;
}

/* Button */

.hikingButtonWrapper {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.hikingButton {
  background: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 0.4em 1em;
  position: relative;
  z-index: 2;
  transition: all 0.7s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  margin-bottom: 2em;
}

.hikingButton:hover {
  box-shadow: inset 10em 0 0 0 #fff;
  color: #000;
}

@media (min-width: 48em) {
  .hikingContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    height: 100vh;
  }

  .hikingImgWrapper img {
    height: 100%;
  }

  .hikingInfoWrapper {
    margin-top: 3.2em;
  }

  .cardTitle h1 {
    font-size: 3rem;
  }
}

.backToHome {
  position: absolute;
  top: 3.5em;
  margin-left: 0.9em;
}

.backToHome h3 {
  font-size: 2.5rem;
  color: #2d3047;
}

.hikingContentWrapper {
  width: 100%;
}

.hikingContent {
  height: 100vh;
}

.hikingImgWrapper img {
  width: 100%;
  object-fit: cover;
}

.hikingInfo {
  width: 100%;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.hikingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Title */

.cardTitle {
  margin-top: 0.8em;
  text-align: center;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle h1 {
  font-size: 2rem;
  font-family: "Dancing Script", cursive;
}

.cardTitle::after {
  content: "";
  background: #fff;
  display: block;
  margin-top: 0.3em;
  width: 20%;
  height: 0.31em;
}

.hikingBorderContent {
  text-align: center;
  border: 3px dashed #fff;
  margin: 1.5em 1.5em 1em 1.5em;
  padding: 1em;
}

/* Button */

.hikingButtonWrapper {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.hikingButton {
  background: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 0.4em 1em;
  position: relative;
  z-index: 2;
  transition: all 0.7s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  margin-bottom: 2em;
}

.hikingButton:hover {
  box-shadow: inset 10em 0 0 0 #fff;
  color: #000;
}

@media (min-width: 48em) {
  .hikingContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    height: 100vh;
  }

  .hikingImgWrapper img {
    height: 100%;
  }

  .hikingInfoWrapper {
    margin-top: 3.2em;
  }

  .cardTitle h1 {
    font-size: 3rem;
  }
}

.backToHome {
  position: absolute;
  top: 3.5em;
  margin-left: 0.9em;
}

.backToHome h3 {
  font-size: 2.5rem;
  color: #2d3047;
}

.hikingContentWrapper {
  width: 100%;
}

.hikingContent {
  height: 100vh;
}

.hikingImgWrapper img {
  width: 100%;
  object-fit: cover;
}

.hikingInfo {
  width: 100%;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.hikingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Title */

.cardTitle {
  margin-top: 0.8em;
  text-align: center;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle h1 {
  font-size: 2rem;
  font-family: "Dancing Script", cursive;
}

.cardTitle::after {
  content: "";
  background: #fff;
  display: block;
  margin-top: 0.3em;
  width: 20%;
  height: 0.31em;
}

.hikingBorderContent {
  text-align: center;
  border: 3px dashed #fff;
  margin: 1.5em 1.5em 1em 1.5em;
  padding: 1em;
}

/* Button */

.hikingButtonWrapper {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.hikingButton {
  background: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 0.4em 1em;
  position: relative;
  z-index: 2;
  transition: all 0.7s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  margin-bottom: 2em;
}

.hikingButton:hover {
  box-shadow: inset 10em 0 0 0 #fff;
  color: #000;
}

@media (min-width: 48em) {
  .hikingContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    height: 100vh;
  }

  .hikingImgWrapper img {
    height: 100%;
  }

  .hikingInfoWrapper {
    margin-top: 3.2em;
  }

  .cardTitle h1 {
    font-size: 3rem;
  }
}

.backToHome {
  position: absolute;
  top: 3.5em;
  margin-left: 0.9em;
}

.backToHome h3 {
  font-size: 2.5rem;
  color: #2d3047;
}

.hikingContentWrapper {
  width: 100%;
}

.hikingContent {
  height: 100vh;
}

.hikingImgWrapper img {
  width: 100%;
  object-fit: cover;
}

.hikingInfo {
  width: 100%;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.hikingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Title */

.cardTitle {
  margin-top: 0.8em;
  text-align: center;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle h1 {
  font-size: 2rem;
  font-family: "Dancing Script", cursive;
}

.cardTitle::after {
  content: "";
  background: #fff;
  display: block;
  margin-top: 0.3em;
  width: 20%;
  height: 0.31em;
}

.hikingBorderContent {
  text-align: center;
  border: 3px dashed #fff;
  margin: 1.5em 1.5em 1em 1.5em;
  padding: 1em;
}

/* Button */

.hikingButtonWrapper {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.hikingButton {
  background: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 0.4em 1em;
  position: relative;
  z-index: 2;
  transition: all 0.7s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  margin-bottom: 2em;
}

.hikingButton:hover {
  box-shadow: inset 10em 0 0 0 #fff;
  color: #000;
}

@media (min-width: 48em) {
  .hikingContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    height: 100vh;
  }

  .hikingImgWrapper img {
    height: 100%;
  }

  .hikingInfoWrapper {
    margin-top: 3.2em;
  }

  .cardTitle h1 {
    font-size: 3rem;
  }
}

.backToHome {
  position: absolute;
  top: 3.5em;
  margin-left: 0.9em;
}

.backToHome h3 {
  font-size: 2.5rem;
  color: #2d3047;
}

.hikingContentWrapper {
  width: 100%;
}

.hikingContent {
  height: 100vh;
}

.hikingImgWrapper img {
  width: 100%;
  object-fit: cover;
}

.hikingInfo {
  width: 100%;
  background: #141e30; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.hikingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Title */

.cardTitle {
  margin-top: 0.8em;
  text-align: center;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle h1 {
  font-size: 2rem;
  font-family: "Dancing Script", cursive;
}

.cardTitle::after {
  content: "";
  background: #fff;
  display: block;
  margin-top: 0.3em;
  width: 20%;
  height: 0.31em;
}

.hikingBorderContent {
  text-align: center;
  border: 3px dashed #fff;
  margin: 1.5em 1.5em 1em 1.5em;
  padding: 1em;
}

/* Button */

.hikingButtonWrapper {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.hikingButton {
  background: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 0.4em 1em;
  position: relative;
  z-index: 2;
  transition: all 0.7s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  margin-bottom: 2em;
}

.hikingButton:hover {
  box-shadow: inset 10em 0 0 0 #fff;
  color: #000;
}

@media (min-width: 48em) {
  .hikingContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    height: 100vh;
  }

  .hikingImgWrapper img {
    height: 100%;
  }

  .hikingInfoWrapper {
    margin-top: 3.2em;
  }

  .cardTitle h1 {
    font-size: 3rem;
  }
}

.backToHome {
  position: absolute;
  top: 3.5em;
  margin-left: 0.9em;
}

.backToHome h3 {
  font-size: 2.5rem;
  color: #2d3047;
}

