.servicesPartTwoWrapper {
  position: absolute;
  width: 100%;
  top: 100%;
}

.servicesPartTwoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.servicesPartTwoContent h1 {
  margin-top: 0.5em;
}