.carouselWrapper {
  width: 100%;
}

.carouselContent {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -25em;
  width: 100%;
}

.carouselInnerWrapper {
  width: 100%;
  background: white;
  padding: 2.5em;
}

.slick-prev::before,
.slick-next::before {
  color: #555 !important;
}

.carouselSlides img {
  margin: 0 auto;
  width: 350px;
}

.carouselInnerWrapper h1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  margin-bottom: 1.5em;
}

.icons {
  color: red;
}

/* Sliders */

.carouselArrow {
  font-size: 1.5rem;
}
