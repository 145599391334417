@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.homeContentWrapper {
  width: 100%;
}

.homeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: url("../../assets/homeBackground.jpg") rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  padding: 0em 1.5em;
}

.homeContent h1 {
  color: white;
  font-family: "Poppins", sans-serif;
}

.homeContent h3 {
  color: white;
  margin-bottom: 0.7em;
}

.homeButtonWrapper {
  display: flex;
}

.homeButtonWrapper > * {
  margin: 0.5em;
}

.homeButton1 {
  padding: 0.7em;
  background: none;
  color: white;
  border: 3px solid #fff;
  font-weight: bold;
  transition: all 0.4s ease-in-out;
}

.homeButton1:hover {
  background: #fff;
  color: #000;
}

.homeButton2 {
  padding: 0.9em;
  background: #fff;
  color: #000;
  font-weight: bold;
  border: none;
  box-shadow: inset 0 0 0 0 #000;
  transition: all 0.4s ease-in-out;
}

.homeButton2:hover {
  box-shadow: inset 10em 0 0 0 #000;
  color: white;
}

@media (min-width: 49em) {
  .homeContent h1 {
    font-size: 3.5rem;
  }

  .homeContent h3 {
    font-size: 2rem;
  }
}
